import React from 'react'
import { Form, Input, Icon, Button, Card, Alert } from 'antd'
import { Redirect } from 'react-router-dom'

class LoginForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null, loading: false, success: false }
    }
    handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ error: null, loading: true, success: false })
                fetch('https://iot.eplusi.net/api/user/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.status === 1) {
                            this.setState({ success: true })
                            sessionStorage.setItem('TOKEN', res['data']['token'])
                            window.location.href = "/location"
                        }
                        else
                            this.setState({ error: res.message })
                    })
                    .finally(() => this.setState({ loading: false }))
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <Form onSubmit={this.handleSubmit} method='POST'>
                <Form.Item>
                    {getFieldDecorator('user_name', { rules: [{ required: true, message: 'User name is required' }] })(
                        <Input placeholder='User name' prefix={<Icon type='user' />} />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', { rules: [{ required: true, message: 'Password is required' }] })(
                        <Input type='password' placeholder='Password' prefix={<Icon type='lock' />} />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit' loading={this.state.loading}>Login</Button>
                </Form.Item>
                {this.state.error && <Alert message={this.state.error} type='error' />}
                {this.state.success && <Alert message='Login successfully. Redirecting...' type='success' />}
            </Form>
        )
    }
}

const WrapedLoginForm = Form.create({ 'name': 'login' })(LoginForm)

export default class Login extends React.Component {

    render() {
        return (
            <div style={{ position: 'fixed', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                <Card style={{ width: 390, boxShadow: '1px 1px 4px' }} cover={<div style={{ width: '100%', textAlign: 'center', backgroundColor: 'rgba(0,0,0,0)', padding: '5% 5% 0 5%' }}><img src='logo.png' style={{ width: 200, height: 'auto', margin: 'auto' }} /><br/><br/><span style={{fontSize: '1.2em', fontWeight: 'bold'}}>Action Environment Control System</span></div>}>
                    <WrapedLoginForm />
                </Card>
            </div>
        )
    }
}