import React from 'react'
import { Form, Input, Modal } from 'antd'
import RelayApi from '../utils/RelayApi'

class EditSlaveFormInf extends React.Component {
    constructor(props) {
        super(props)
    }

    handleSubmit = e => {
        e.preventDefault()
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <Form layout='horizontal' onSubmit={this.handleSubmit}>
                <Form.Item label='Device ID'>
                    {getFieldDecorator('device_id', {
                        rules: [{ required: false }]
                    })(<Input disabled />)}
                </Form.Item>
                <Form.Item label='Device name'>
                    {getFieldDecorator('device_name', {
                        rules: [{ required: false }]
                    })(<Input />)}
                </Form.Item>
            </Form >
        )
    }
}

const EditSlaveForm = Form.create({
    mapPropsToFields(props) {
        const { slave } = props
        return {
            device_id: Form.createFormField({
                value: slave.device_id
            }),
            device_name: Form.createFormField({
                value: slave.device_name
            }),
        }
    }
})(EditSlaveFormInf)

export default class EditSlaveDialog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            editModalVisible: false,
            slave: props.slave,
            loading: false
        }
    }

    showEditModal = () => this.setState({ editModalVisible: true })
    hideEditModal = () => this.setState({ editModalVisible: false, slave: null }, this.props.onClose)
    onOk = () => {
        console.log(this.formInstance)
        this.formInstance.props.form.validateFields((err, values) => {
            if (!err) {
                let finalSlave = { ...this.state.slave, ...values }
                console.log(finalSlave)
                delete finalSlave.sensors
                this.setState({ loading: true })
                RelayApi.updateSlave(finalSlave)
                    .then(res => {
                        if (res['status'] === 'OK') {
                            this.setState({ loading: false }, this.hideEditModal)
                        }
                    })
            }
        });
        this.hideEditModal()
    }

    componentWillReceiveProps(props) {
        if (props.slave && !this.state.editModalVisible) this.setState({ editModalVisible: true, slave: props.slave })
        if (!props.slave && this.state.editModalVisible) this.setState({ editModalVisible: false, slave: null })
    }

    render() {
        return (
            <Modal
                title='Edit slave'
                visible={this.state.editModalVisible}
                onCancel={this.hideEditModal}
                onOk={this.onOk}
                confirmLoading={this.state.loading}>
                {this.state.slave && <EditSlaveForm slave={this.state.slave} wrappedComponentRef={(inst) => this.formInstance = inst} />}
            </Modal>
        )
    }
}