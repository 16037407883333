import React from 'react'
import { Table, Divider, Layout } from 'antd'
import RelayApi from '../../utils/RelayApi';
import getParam from '../../utils/ParamHelper'
import EditSlaveDialog from '../../modal/EditSlaveDialog';
import EditSensorDialog from '../../modal/EditSensorDialog';
import './Logs.css'
import moment from 'moment';

export default class Logs extends React.Component {
    constructor(props) {
        super(props)

        const locationId = getParam('location_id')

        this.state = {
            locationId,
            logs: [],
            loading: false,
        }
    }

    componentDidMount() {
        this.getLogs()
    }

    getLogs = () => {
        this.setState({ loading: true })
        RelayApi.getSensorLog(this.state.locationId)
            .then(res => {
                this.setState({ logs: res['data'], loading: false })
            })
        // .finally(() => this.setState({ loading: false }))
    }

    render() {
        const columns = [
            { title: 'Device ID', dataIndex: 'device_id', key: 'device_id' },
            { title: 'Device name', dataIndex: 'device_name', key: 'device_name' },
            { title: 'Sensor type', dataIndex: 'sensor_type', key: 'sensor_type' },
            { title: 'Status', dataIndex: 'status', key: 'status' },
            { title: 'Value', dataIndex: 'value', key: 'value' },
            {
                title: 'Time',
                dataIndex: 'time',
                key: 'time',
                render: (text, record) => (
                    <span>{moment.unix(+record.time).format()}</span>
                )
            },
            // {
            //     title: 'Action',
            //     dataIndex: 'operation',
            //     key: 'operation',
            //     render: (text, record) => (
            //         <span>
            //             <a onClick={() => this.onEditSlave(record)}>Edit</a>
            //             {/* <Divider type="vertical" />
            //             <a>Delete</a> */}
            //         </span>
            //     ),
            // },
        ]

        return (
            <div className='.slave-config-container'>
                <Layout>
                    <Layout.Header style={{ backgroundColor: 'rgba(0,0,0,0)' }}><img src='/logo.png' height='50px' width='auto' /><span> Sensor logs</span></Layout.Header>
                    <Layout.Content>
                        <Table columns={columns} dataSource={this.state.logs} />
                    </Layout.Content>
                </Layout>
            </div>
        )
    }
}