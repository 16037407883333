import React from 'react'
import { List, Card, Layout, Icon, Modal, Form, Input } from 'antd'
import { Link } from 'react-router-dom'
import './Location.css'
import EditLocationDialog from '../../modal/EditLocationDialog'
import RelayApi from '../../utils/RelayApi'

export default class Location extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            editModalVisible: false,
            editingLocation: null,
            locations: [],
            loading: false
        }
    }

    componentDidMount() {
        this.getLocations()
    }

    doLogout = () => {
        sessionStorage.removeItem('TOKEN')
        window.location.href = '/login'
    }

    getLocations = () => {
        this.setState({ loading: true, editingLocation: null })
        RelayApi.getLocations()
            .then(res => this.setState({ locations: [res['data'][0]], loading: false }))
    }

    onEditLocation = (location) => {
        this.setState({ editingLocation: location })
    }

    render() {
        const { Header, Footer, Content } = Layout
        return (
            <div className='location-container'>
                <Layout>
                    <Header style={{ backgroundColor: 'rgba(0,0,0,0)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img src='/logo.png' height='50px' width='auto' />
                        <span>&nbsp;Dashboard</span>
                        <div style={{flexGrow: 1}} />
                        <a href='#' onClick={this.doLogout}>Logout</a>
                    </Header>
                    <Content style={{ padding: 10 }}>
                        <List grid={{ gutter: 16, column: 2 }}
                            dataSource={this.state.locations}
                            renderItem={item => (
                                <List.Item>
                                    <Card title={<Link to={'/maps?location_id=' + item.location_id} >{item.location}</Link>}
                                        hoverable={true}
                                        cover={<Link to={'/maps?location_id=' + item.location_id} ><div className='location-card-cover' style={{ backgroundImage: 'url(' + item.cover_url + ')' }}></div></Link>}
                                        actions={[
                                            <Icon type="edit" key="edit" onClick={() => this.onEditLocation(item)} />,
                                            <Link to={'/slaves?location_id=' + item.location_id} ><Icon type="setting" key="setting" /></Link>,
                                            // <Link to={'/maps?location_id=' + item.location_id} ><Icon type="link" key="link" /></Link>,
                                            <Link to={'/logs?location_id=' + item.location_id} ><Icon type="exception" key="history" /></Link>,
                                        ]}>
                                        {item.description}
                                    </Card>
                                </List.Item>
                            )} />
                    </Content>
                    {/* <Footer className='location-footer'>Eplusi technology @2019</Footer> */}
                </Layout>
                <EditLocationDialog location={this.state.editingLocation} onClose={this.getLocations} />
            </div>
        )
    }
}