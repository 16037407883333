import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import './App.css'
import { Login } from './page/login';
import { Config } from './page/config';
import { Location } from './page/location'
import { Maps } from './page/maps';
import { SlaveConfig } from './page/slave';
import { Logs } from './page/logs';

class App extends React.Component {

  constructor(props) {
    super(props)
  }

  PrivateRouter = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          sessionStorage.getItem('TOKEN') ? (
            children
          ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location }
                }}
              />
            )
        }
      />
    );
  }

  render() {
    return (
      <Router>
        <div>
          <nav>
          </nav>
          <Switch>
            <this.PrivateRouter path="/maps">
              <Maps />
            </this.PrivateRouter>
            <this.PrivateRouter path="/config">
              <Config />
            </this.PrivateRouter>
            <this.PrivateRouter path="/location">
              <Location />
            </this.PrivateRouter>
            <this.PrivateRouter path="/slaves">
              <SlaveConfig />
            </this.PrivateRouter>
            <this.PrivateRouter path="/logs">
              <Logs />
            </this.PrivateRouter>
            <Route path="/login">
              <Login />
            </Route>
            <this.PrivateRouter path="/">
              <Redirect to="/location"></Redirect>
            </this.PrivateRouter>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
