import React from 'react'
import getParam from '../../utils/ParamHelper'
import io from 'socket.io-client'
import RelayApi from '../../utils/RelayApi';
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Tooltip, Button, Icon, Table, Card, Popconfirm } from 'antd';
import './Maps.css'

export default class Maps extends React.Component {
    constructor(props) {
        super(props)

        const locationId = getParam('location_id')

        this.state = {
            sensors: [],
            errors: [],
            logs: [],
            loading: false,
            locationId
        }

    }

    loadSensorsConfig = () => {
        this.setState({ loading: true })
        RelayApi.getSensors(this.state.locationId)
            .then(res => {
                const sensors = res['data']
                this.setState({ sensors, loading: false }, () => {
                    let errors = []
                    sensors.forEach((sensor, i) => {
                        errors = this.checkCurrentError(errors, sensor, i)
                    })
                    this.setState({ errors })
                })
            })
        // .finally(() => this.setState({ loading: false }))
    }

    loadHistory = () => {
        // console.log('load history')
        RelayApi.getSensorLog(this.state.locationId, 20)
            .then(res => {
                this.setState({ logs: res['data'] })
            })

        this.timer = setTimeout(this.loadHistory, 30000)
    }

    checkCurrentError(errors, sensor, i) {
        const index = errors.findIndex(e => e.id === sensor.device_id)
        let sibling = null
        if (i > 0 && this.state.sensors[i - 1]['device_id'] === sensor.device_id)
            sibling = this.state.sensors[i - 1]
        if (i < this.state.sensors.length - 1 && this.state.sensors[i + 1]['device_id'] === sensor.device_id)
            sibling = this.state.sensors[i + 1]
        if (sensor.status === 'IN_SAFE_ZONE') {
            if (index >= 0) {
                if (sibling) {
                    if (sibling.status === 'IN_SAFE_ZONE') {
                        errors.splice(index, 1)
                        return errors
                    }
                } else {
                    errors.splice(index, 1)
                    return errors
                }
            } else {
                if (sibling) {
                    if (sibling.status === 'IN_SAFE_ZONE') {
                        return errors
                    }
                } else {
                    return errors
                }
            }
        }

        const siblingIsHumid = sibling ? sibling.sensor_type.includes('HUMID') : sensor.sensor_type.includes('TEMP')
        const error = {
            name: this.state.sensors[i].device_name,
            id: sensor.device_id,
            temp: {
                value: siblingIsHumid ? sensor.value : (sibling ? sibling.value : null),
                low_threshold: siblingIsHumid ? sensor.low_threshold : (sibling ? sibling.low_threshold : null),
                high_threshold: siblingIsHumid ? sensor.high_threshold : (sibling ? sibling.high_threshold : null),
                status: siblingIsHumid ? sensor.status : (sibling ? sibling.status : null)
            },
            hum: {
                value: siblingIsHumid ? (sibling ? sibling.value : null) : sensor.value,
                low_threshold: siblingIsHumid ? (sibling ? sibling.low_threshold : null) : sensor.low_threshold,
                high_threshold: siblingIsHumid ? (sibling ? sibling.high_threshold : null) : sensor.high_threshold,
                status: siblingIsHumid ? (sibling ? sibling.status : null) : sensor.status
            }
        }

        if (index >= 0) {
            errors[index] = error
        }
        else
            errors.push(error)
        return errors
    }

    componentDidMount() {
        this.loadSensorsConfig()
        this.loadHistory()
        this.socket = io('https://relay.eplusi.net')
        this.socket.on('data-detail', data => {
            // console.log(data)
            let errors = this.state.errors
            this.state.sensors.forEach((sensor, i) => {
                if (sensor.device_id === data.deviceId && sensor.sensor_type === data.sensorType) {
                    // console.log('is same')
                    this.state.sensors[i].value = data.value
                    this.state.sensors[i].time = data.time
                    this.state.sensors[i].status = data.status
                    this.state.sensors[i].low_threshold = data.low_threshold
                    this.state.sensors[i].high_threshold = data.high_threshold
                    // console.log(this.state.sensors[i])
                }

                errors = this.checkCurrentError(errors, this.state.sensors[i], i)
            })

            this.setState({ sensors: this.state.sensors, errors })
        })
    }

    componentWillUnmount() {
        this.socket.disconnect()
    }

    renderHistoryLogs() {
        const columns = [
            {
                title: 'Sensor',
                dataIndex: 'device_name',
                key: 'device_name',
                render: (text, record) => {
                    // if (text.length > 25)
                    //     return <marquee>{text}</marquee>
                    // return <span>{text}</span>
                    return text.split('-')[0].trim()
                }
            },
            {
                title: 'Time',
                dataIndex: 'time',
                key: 'time',
                render: (text, record) => (
                    <span>{moment.unix(+record.time).format('H:m')}</span>
                )
            },
            {
                title: 'Date',
                dataIndex: 'time',
                key: 'date',
                render: (text, record) => (
                    <span>{moment.unix(+record.time).format('D.M')}</span>
                )
            },
            {
                title: 'Type',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    const type = record['sensor_type'].includes('HUMID') ? 'Hum' : 'Temp'
                    switch (text) {
                        case 'DISCONNECTED':
                            return <span>{type} disconnected</span>
                        case 'OVER_SAFE_ZONE':
                            return <span>{type} high</span>
                        case 'UNDER_SAFE_ZONE':
                            return <span>{type} low</span>
                        default:
                            return <span>{type} normal</span>
                    }
                }
            },
        ]
        return (
            <Card title="Alarm log" style={{ height: 510, width: 590 }}>
                <div style={{ height: '100%', width: 590, position: 'relative' }}>
                    <Table columns={columns} dataSource={this.state.logs} size='small' style={{ height: '100%', width: 500 }} />
                    <Popconfirm title='Are you sure?'
                        onConfirm={() => {
                            RelayApi.deleteSensorLog(this.state.locationId)
                                .then(res => {
                                    clearTimeout(this.timer)
                                    this.loadHistory()
                                })
                        }}>
                        <a href='#' style={{ position: 'absolute', bottom: 5, right: 5, backgroundColor: 'red', padding: 10, color: 'white' }}>Clear log</a>
                    </Popconfirm>
                </div>
            </Card>
        )
    }

    renderCurrentAlert() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => {
                    return text.split('-')[0].trim()
                }
            },
            {
                title: 'Temp C',
                dataIndex: 'temp.value',
                key: 'temp_val',
            },
            {
                title: 'Limit up',
                dataIndex: 'temp.high_threshold',
                key: 'temp_high',
            },
            {
                title: 'Limit lower',
                dataIndex: 'temp.low_threshold',
                key: 'temp_low',
            },
            {
                title: 'Alarm',
                dataIndex: 'temp.status',
                key: 'temp_status',
                render: (text, record) => {
                    switch (text) {
                        case 'OVER_SAFE_ZONE':
                        case 'UNDER_SAFE_ZONE':
                            return <div style={{ backgroundColor: 'red', width: 30, height: 10 }}> </div>
                        case 'DISCONNECTED':
                            return <div style={{ backgroundColor: '#ddd', width: 30, height: 10 }}> </div>
                        case 'IN_SAFE_ZONE':
                            return <div style={{ backgroundColor: 'green', width: 30, height: 10 }}> </div>
                        default:
                            return null
                    }
                }
            },
            {
                title: 'Hum %',
                dataIndex: 'hum.value',
                key: 'hum_val',
            },
            {
                title: 'Limit up',
                dataIndex: 'hum.high_threshold',
                key: 'hum_high',
            },
            {
                title: 'Limit lower',
                dataIndex: 'hum.low_threshold',
                key: 'hum_low',
            },
            {
                title: 'Alarm',
                dataIndex: 'hum.status',
                key: 'hum_status',
                render: (text, record) => {
                    switch (text) {
                        case 'OVER_SAFE_ZONE':
                        case 'UNDER_SAFE_ZONE':
                            return <div style={{ backgroundColor: 'red', width: 30, height: 10 }}> </div>
                        case 'DISCONNECTED':
                            return <div style={{ backgroundColor: '#ddd', width: 30, height: 10 }}> </div>
                        case 'IN_SAFE_ZONE':
                            return <div style={{ backgroundColor: 'green', width: 30, height: 10 }}> </div>
                        default:
                            return null
                    }
                }
            },
        ]
        // console.log(this.state.errors)
        return (
            <Card title="Current Alert" style={{ height: 510 }}>
                <Table columns={columns} dataSource={this.state.errors} size='small' style={{ height: '100%' }} />
            </Card>
        )
    }

    renderRightPanel() {
        return (
            <div className='alert-container' style={{ width: 590, height: 1020 }}>
                {this.renderCurrentAlert()}
                {this.renderHistoryLogs()}
            </div>
        )
    }

    render() {
        const totalError = this.state.groundDisCount + this.state.groundHighCount + this.state.groundLowCount + this.state.floor1DisCount + this.state.floor1HighCount + this.state.floor1LowCount
        return (
            <div className={+this.state.locationId === 1 ? 'container-ground' : 'container-floor1'}>
                <div className="container-header">
                    <Button.Group>
                        <Button type='primary'><Link to="/location"><Icon type='home' /></Link></Button>
                        <Button type='primary' onClick={this.loadSensorsConfig} loading={this.state.loading}><Icon type='sync' /></Button>
                    </Button.Group>
                    <div style={{ width: 15 }}></div>
                    <iframe src="https://freesecure.timeanddate.com/clock/i771ezq6/n218/fn2/fs15/tcccc/bo2/pa6/tt0/tw0/td2/th1/tb2" frameborder="0" width="213" height="31"></iframe>
                    <div style={{ flexGrow: 1 }}></div>
                    <img src='logo.png' style={{ width: 200, height: 'auto', margin: 'auto' }} />

                </div>
                {this.state.sensors.filter(sensor => +sensor.location_id === +this.state.locationId).map((sensor, i) => {
                    let time = 'unknown'
                    if (sensor.time)
                        time = moment.unix(sensor.time).format()
                    let cssClass = 'value'
                    if (sensor.status === 'DISCONNECTED') cssClass += ' disconnected'
                    else {
                        cssClass += sensor.status === 'OVER_SAFE_ZONE' ? ' alert-high' : ''
                        cssClass += sensor.status === 'UNDER_SAFE_ZONE' ? ' alert-low' : ''
                    }

                    return (
                        <span title={time} className={cssClass} key={i} style={{ top: sensor.css_value_top, left: sensor.css_value_left }}>{sensor.value || 'NaN'}</span>
                    )
                })}
                {this.renderRightPanel()}
            </div>
        );
    }
}