import React from 'react'
import { Form, Input, Modal } from 'antd'
import RelayApi from '../utils/RelayApi'

class EditLocationFormInf extends React.Component {
    constructor(props) {
        super(props)
    }

    handleSubmit = e => {
        e.preventDefault()
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <Form layout='horizontal' onSubmit={this.handleSubmit}>
                <Form.Item label='Location'>
                    {getFieldDecorator('location', {
                        rules: [{ required: false }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label='Description'>
                    {getFieldDecorator('description', {
                        rules: [{ required: false }]
                    })(<Input />)}
                </Form.Item>
                {/* <Form.Item label='Width'>
                    {getFieldDecorator('width', {
                        rules: [{ required: false }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label='Height'>
                    {getFieldDecorator('height', {
                        rules: [{ required: false }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label='Background image URL'>
                    {getFieldDecorator('background_url', {
                        rules: [{ required: false }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label='Cover image URL'>
                    {getFieldDecorator('cover_url', {
                        rules: [{ required: false }]
                    })(<Input />)}
                </Form.Item> */}
            </Form >
        )
    }
}

const EditLocationForm = Form.create({
    mapPropsToFields(props) {
        const { location } = props
        return {
            location: Form.createFormField({
                value: location.location
            }),
            description: Form.createFormField({
                value: location.description
            }),
            // width: Form.createFormField({
            //     value: location.width
            // }),
            // height: Form.createFormField({
            //     value: location.height
            // }),
            // background_url: Form.createFormField({
            //     value: location.background_url
            // }),
            // cover_url: Form.createFormField({
            //     value: location.cover_url
            // }),
        }
    }
})(EditLocationFormInf)

export default class EditLocationDialog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            editModalVisible: false,
            location: props.location,
            loading: false
        }
    }

    showEditModal = () => this.setState({ editModalVisible: true })
    hideEditModal = () => this.setState({ editModalVisible: false, loading: false, location: null }, this.props.onClose)
    onOk = () => {
        // console.log(this.formInstance)
        this.formInstance.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
                let finalLocation = { ...this.state.location, ...values }
                console.log(finalLocation)
                this.setState({ loading: true })
                RelayApi.updateLocation(finalLocation)
                    .then(res => {
                        if (res['status'] === 'OK') {
                            this.setState({ loading: false }, this.hideEditModal)
                        }
                    })
            }
        });
    }

    componentWillReceiveProps(props) {
        if (props.location && !this.state.editModalVisible) this.setState({ editModalVisible: true, location: props.location })
        if (!props.location && this.state.editModalVisible) this.setState({ editModalVisible: false, location: null })
    }

    render() {
        return (
            <Modal
                title='Edit location'
                visible={this.state.editModalVisible}
                onCancel={this.hideEditModal}
                onOk={this.onOk}
                confirmLoading={this.state.loading}>
                {this.state.location && <EditLocationForm location={this.state.location} wrappedComponentRef={(inst) => this.formInstance = inst} />}
            </Modal>
        )
    }
}