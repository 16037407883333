import React from 'react'
import RelayApi from '../../utils/RelayApi';
import './Config.css'
import { Table } from 'antd';

export default class Config extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sensors: [],
            selected: {},
            newMode: false,
            editMode: false,
            deleteComfirm: false,
            loading: false,
            userLevel: this.checkPermission()
        }
    }

    componentDidMount() {
        this.loadAllSensorsConfig()
    }

    loadAllSensorsConfig = () => {
        this.setState({ selected: {}, newMode: false, editMode: false, loading: true })
        RelayApi.getSensors()
            .then(res => {
                this.setState({ sensors: res['data'] })
            })
            .finally(() => this.setState({ loading: false }))
    }

    checkPermission = () => {
        const userInfo = require('jsonwebtoken').decode(sessionStorage.getItem('TOKEN'))
        return userInfo.level
    }

    onSelected = (sensor) => {
        if (this.state.editMode) return
        this.setState({ selected: { ...sensor } })
    }

    onAddNew = () => {
        this.setState({
            selected: {},
            editMode: true,
            newMode: true
        })
    }

    onEdit = () => {
        this.setState({ editMode: true })
    }

    onSave = () => {
        if (!this.state.editMode) return
        this.setState({ loading: true })
        if (this.state.newMode) {
            RelayApi.addSensor(this.state.selected)
                .then(res => {
                    this.loadAllSensorsConfig()
                })
                .finally(() => this.setState({ loading: false }))
        } else {
            RelayApi.updateSensor(this.state.selected)
                .then(res => {
                    this.loadAllSensorsConfig()
                })
                .finally(() => this.setState({ loading: false }))
        }
    }

    render() {
        const columns = [
            {
                title: 'Device ID',
                dataIndex: 'device_id',
                key: 'device_id'
            },
            {
                title: 'Device type',
                dataIndex: 'device_type',
                key: 'device_type'
            },
            {
                title: 'Owner',
                dataIndex: 'owner',
                key: 'owner'
            },
            {
                title: 'Device name',
                dataIndex: 'device_name',
                key: 'device_name'
            },
            {
                title: 'Location',
                dataIndex: 'location',
                key: 'location'
            },
        ]

        const testData = [
            {
                device_id: 'device123123',
                device_type: 'storage',
                owner: 'test',
                device_name: 'test name',
                location: 'ground'
            },
            {
                device_id: 'device123123',
                device_type: 'storage',
                owner: 'test',
                device_name: 'test name',
                location: 'ground'
            },
            {
                device_id: 'device123123',
                device_type: 'storage',
                owner: 'test',
                device_name: 'test name',
                location: 'ground'
            },
            {
                device_id: 'device123123',
                device_type: 'storage',
                owner: 'test',
                device_name: 'test name',
                location: 'ground'
            },
            {
                device_id: 'device123123',
                device_type: 'storage',
                owner: 'test',
                device_name: 'test name',
                location: 'ground'
            },
            {
                device_id: 'device123123',
                device_type: 'storage',
                owner: 'test',
                device_name: 'test name',
                location: 'ground'
            },
            {
                device_id: 'device123123',
                device_type: 'storage',
                owner: 'test',
                device_name: 'test name',
                location: 'ground'
            },
            {
                device_id: 'device123123',
                device_type: 'storage',
                owner: 'test',
                device_name: 'test name',
                location: 'ground'
            },
            {
                device_id: 'device123123',
                device_type: 'storage',
                owner: 'test',
                device_name: 'test name',
                location: 'ground'
            },
            {
                device_id: 'device123123',
                device_type: 'storage',
                owner: 'test',
                device_name: 'test name',
                location: 'ground'
            },
            {
                device_id: 'device123123',
                device_type: 'storage',
                owner: 'test',
                device_name: 'test name',
                location: 'ground'
            },
            {
                device_id: 'device123123',
                device_type: 'storage',
                owner: 'test',
                device_name: 'test name',
                location: 'ground'
            },
            {
                device_id: 'device123123',
                device_type: 'storage',
                owner: 'test',
                device_name: 'test name',
                location: 'ground'
            },
        ]
        return (
            <Table columns={columns} dataSource={testData} pagination={false}/>
        )
    }

    // render() {
    //     return (
    //         <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: '1em', position: 'fixed', margin: 'auto', overflow: 'auto', marginTop: '1em' }}>
    //             <form>
    //                 <div className="row">
    //                     <div className="col">
    //                         <div className="form-group">
    //                             <label>Device ID</label>
    //                             <input type="text" className="form-control" placeholder="required" value={this.state.selected.device_id ? this.state.selected.device_id : ''}
    //                                 onChange={(event) => {
    //                                     this.state.selected.device_id = event.target.value
    //                                     this.setState({ selected: this.state.selected })
    //                                 }} disabled={(this.state.editMode) ? "" : "disabled"} />
    //                         </div>

    //                     </div>
    //                     <div className="col">
    //                         <div className="form-group">
    //                             <label>Sensor Name</label>
    //                             <input type="text" className="form-control" placeholder="required" value={this.state.selected.sensor_name ? this.state.selected.sensor_name : ''}
    //                                 onChange={(event) => {
    //                                     this.state.selected.sensor_name = event.target.value
    //                                     this.setState({ selected: this.state.selected })
    //                                 }} disabled={(this.state.editMode) ? "" : "disabled"} />
    //                         </div>
    //                     </div>
    //                     <div className="col">
    //                         <div className="form-group">
    //                             <label>Sensor Type</label>
    //                             <input type="text" className="form-control" placeholder="required" value={this.state.selected.sensor_type ? this.state.selected.sensor_type : ''}
    //                                 onChange={(event) => {
    //                                     this.state.selected.sensor_type = event.target.value
    //                                     this.setState({ selected: this.state.selected })
    //                                 }} disabled={(this.state.editMode) ? "" : "disabled"} />
    //                         </div>
    //                     </div>
    //                     <div className="col">
    //                         <div className="form-group">
    //                             <label>Location</label>
    //                             <input type="text" className="form-control" placeholder="required" value={this.state.selected.location ? this.state.selected.location : ''}
    //                                 onChange={(event) => {
    //                                     this.state.selected.location = event.target.value
    //                                     this.setState({ selected: this.state.selected })
    //                                 }} disabled={(this.state.editMode) ? "" : "disabled"} />
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div className="row">
    //                     {this.state.userLevel === 0 &&
    //                         <div className="col">
    //                             <div className="form-group">
    //                                 <label>CSS Top</label>
    //                                 <input type="text" className="form-control" placeholder="required" value={this.state.selected.css_top ? this.state.selected.css_top : '0'}
    //                                     onChange={(event) => {
    //                                         this.state.selected.css_top = event.target.value
    //                                         this.setState({ selected: this.state.selected })
    //                                     }} disabled={(this.state.editMode) ? "" : "disabled"} />
    //                             </div>
    //                         </div>
    //                     }
    //                     {this.state.userLevel === 0 &&
    //                         <div className="col">
    //                             <div className="form-group">
    //                                 <label>CSS Left</label>
    //                                 <input type="text" className="form-control" placeholder="required" value={this.state.selected.css_left ? this.state.selected.css_left : '0'}
    //                                     onChange={(event) => {
    //                                         this.state.selected.css_left = event.target.value
    //                                         this.setState({ selected: this.state.selected })
    //                                     }} disabled={(this.state.editMode) ? "" : "disabled"} />
    //                             </div>
    //                         </div>
    //                     }
    //                     <div className="col">
    //                         <div className="form-group">
    //                             <label>Alert High</label>
    //                             <input type="number" className="form-control" value={this.state.selected.alert_high ? this.state.selected.alert_high : ''}
    //                                 onChange={(event) => {
    //                                     this.state.selected.alert_high = event.target.value === "" ? null : event.target.value
    //                                     this.setState({ selected: this.state.selected })
    //                                 }} disabled={(this.state.editMode) ? "" : "disabled"} />
    //                         </div>
    //                     </div>
    //                     <div className="col">
    //                         <div className="form-group">
    //                             <label>Alert Low</label>
    //                             <input type="number" className="form-control" value={this.state.selected.alert_low ? this.state.selected.alert_low : ''}
    //                                 onChange={(event) => {
    //                                     this.state.selected.alert_low = event.target.value === "" ? null : event.target.value
    //                                     this.setState({ selected: this.state.selected })
    //                                 }} disabled={(this.state.editMode) ? "" : "disabled"} />
    //                         </div>
    //                     </div>
    //                 </div>
    //             </form>
    //             <div className="row">
    //                 <button className="btn btn-info" onClick={() => window.history.back()}>Back</button>

    //                 {!this.state.editMode &&
    //                     <button className="btn btn-info" onClick={this.loadAllSensorsConfig}>Refresh</button>
    //                 }
    //                 {(!this.state.editMode && this.state.userLevel === 0) &&
    //                     <button className="btn btn-primary" onClick={this.onAddNew}>Add new</button>
    //                 }
    //                 {this.state.editMode &&
    //                     <button className="btn btn-info" onClick={this.onSave}>Save</button>
    //                 }
    //                 {this.state.editMode &&
    //                     <button className="btn btn-primary" onClick={this.loadAllSensorsConfig}>Cancel</button>
    //                 }
    //                 {(!this.state.editMode && this.state.selected.oid) &&
    //                     <button className="btn btn-info" onClick={this.onEdit}>Edit</button>
    //                 }
    //                 {/* {(!this.state.editMode && this.state.selected.oid) &&
    //                     <button className="btn btn-warning">{this.state.deleteComfirm ? 'Yes, delete it' : 'Delete'}</button>
    //                 } */}
    //                 {this.state.loading &&
    //                     <div className="spinner-border text-primary" role="status">
    //                         <span className="sr-only">Loading...</span>
    //                     </div>
    //                 }
    //             </div>
    //             <br />
    //             <div style={{ flexGrow: 1, overflow: 'auto' }}>
    //                 <table className="table table-hover">
    //                     <thead>
    //                         <tr>
    //                             <th scope="col">Device ID</th>
    //                             <th scope="col">Sensor Name</th>
    //                             <th scope="col">Sensor Type</th>
    //                             <th scope="col">Location</th>
    //                             {this.state.userLevel === 0 &&
    //                                 <th scope="col">CSS Top</th>
    //                             }
    //                             {this.state.userLevel === 0 &&
    //                                 <th scope="col">CSS Left</th>
    //                             }
    //                             <th scope="col">Alert High</th>
    //                             <th scope="col">Alert Low</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         {
    //                             this.state.sensors.map((sensor, i) => {
    //                                 return (
    //                                     <tr key={i} style={{ cursor: 'pointer' }} onClick={(event) => this.onSelected(sensor)} className={this.state.selected.oid ?
    //                                         this.state.selected.oid === sensor.oid ? 'selected' : '' : ''}>
    //                                         <td>{sensor.device_id}</td>
    //                                         <td>{sensor.sensor_name}</td>
    //                                         <td>{sensor.sensor_type}</td>
    //                                         <td>{sensor.location}</td>
    //                                         {this.state.userLevel === 0 &&
    //                                             <td>{sensor.css_top}</td>
    //                                         }
    //                                         {this.state.userLevel === 0 &&
    //                                             <td>{sensor.css_left}</td>
    //                                         }
    //                                         <td>{sensor.alert_high}</td>
    //                                         <td>{sensor.alert_low}</td>
    //                                     </tr>
    //                                 )
    //                             })
    //                         }
    //                     </tbody>
    //                 </table>
    //             </div>
    //         </div>
    //     )
    // }
}