import React from 'react'
import { Form, Input, Modal } from 'antd'
import RelayApi from '../utils/RelayApi'

class EditSensorFormInf extends React.Component {
    constructor(props) {
        super(props)
    }

    handleSubmit = e => {
        e.preventDefault()
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <Form layout='horizontal' onSubmit={this.handleSubmit}>
                <Form.Item label='Sensor type'>
                    {getFieldDecorator('sensor_type', {
                        rules: [{ required: false }]
                    })(<Input disabled />)}
                </Form.Item>
                <Form.Item label='High threshold'>
                    {getFieldDecorator('high_threshold', {
                        rules: [{ required: false }]
                    })(<Input type='number' />)}
                </Form.Item>
                <Form.Item label='Low threshold'>
                    {getFieldDecorator('low_threshold', {
                        rules: [{ required: false }]
                    })(<Input type='number' />)}
                </Form.Item>
            </Form >
        )
    }
}

const EditSensorForm = Form.create({
    mapPropsToFields(props) {
        const { sensor } = props
        return {
            sensor_type: Form.createFormField({
                value: sensor.sensor_type
            }),
            high_threshold: Form.createFormField({
                value: sensor.high_threshold
            }),
            low_threshold: Form.createFormField({
                value: sensor.low_threshold
            }),
        }
    }
})(EditSensorFormInf)

export default class EditSensorDialog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            editModalVisible: false,
            sensor: props.sensor,
            loading: false
        }
    }

    showEditModal = () => this.setState({ editModalVisible: true })
    hideEditModal = () => this.setState({ editModalVisible: false, sensor: null }, this.props.onClose)
    onOk = () => {
        console.log(this.formInstance)
        this.formInstance.props.form.validateFields((err, values) => {
            if (!err) {
                let finalSensor = { ...this.state.sensor, ...values }
                console.log(finalSensor)
                this.setState({ loading: true })
                RelayApi.updateSensor(finalSensor)
                    .then(res => {
                        if (res['status'] === 'OK') {
                            this.setState({ loading: false }, this.hideEditModal)
                        }
                    })
            }
        });
    }

    componentWillReceiveProps(props) {
        if (props.sensor && !this.state.editModalVisible) this.setState({ editModalVisible: true, sensor: props.sensor })
        if (!props.sensor && this.state.editModalVisible) this.setState({ editModalVisible: false, sensor: null }, this.props.onClose)
    }

    render() {
        return (
            <Modal
                title='Edit sensor'
                visible={this.state.editModalVisible}
                onCancel={this.hideEditModal}
                onOk={this.onOk}
                confirmLoading={this.state.loading}>
                {this.state.sensor && <EditSensorForm sensor={this.state.sensor} wrappedComponentRef={(inst) => this.formInstance = inst} />}
            </Modal>
        )
    }
}