export default class RelayApi {

    // static HOST = 'http://localhost:8090'
    static HOST = 'https://relay.eplusi.net'

    static getLocations = () => {
        return fetch(this.HOST + '/api/dashboard/locations')
            .then(res => res.json())
    }

    static updateLocation = (location) => {
        return fetch(this.HOST + '/api/dashboard/locations',
            {
                method: 'PUT',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(location)
            }
        ).then(res => res.json())
    }

    static getSensors = (locationId) => {
        return fetch('https://relay.eplusi.net/api/dashboard/sensors/latest' + (locationId ? ('?location_id=' + locationId) : ''))
            .then(res => res.json())
    }

    static getSlaveConfigs = (locationId) => {
        return fetch('https://relay.eplusi.net/api/dashboard/slaves' + (locationId ? ('?location_id=' + locationId) : ''))
            .then(res => res.json())
    }

    static updateSlave = (slave) => {
        return fetch(this.HOST + '/api/dashboard/slaves',
            {
                method: 'PUT',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(slave)
            }
        ).then(res => res.json())
    }

    static getSensorLog = (locationId, limit) => {
        return fetch(this.HOST + '/api/dashboard/sensor_log?location_id=' + locationId + (limit ? `&limit=${limit}` : ''))
            .then(res => res.json())
    }

    static deleteSensorLog = (locationId) => {
        return fetch(this.HOST + '/api/dashboard/sensor_log?location_id=' + locationId, {
            method: 'DELETE'
        })
            .then(res => res.json())
    }

    static addSensor = (sensor) => {
        return fetch('https://relay.eplusi.net/api/action_project/sensor',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(sensor)
            }
        )
            .then(res => res.json())
    }

    static updateSensor = (sensor) => {
        return fetch(this.HOST + '/api/dashboard/sensors',
            {
                method: 'PUT',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(sensor)
            }
        ).then(res => res.json())
    }
}