import React from 'react'
import { Table, Divider, Layout } from 'antd'
import RelayApi from '../../utils/RelayApi';
import getParam from '../../utils/ParamHelper'
import EditSlaveDialog from '../../modal/EditSlaveDialog';
import EditSensorDialog from '../../modal/EditSensorDialog';
import './SlaveConfig.css'

export default class SlaveConfig extends React.Component {
    constructor(props) {
        super(props)

        const locationId = getParam('location_id')

        this.state = {
            locationId,
            slaveConfigs: [],
            loading: false,
            editingSlave: null,
            editingSensor: null
        }
    }

    componentDidMount() {
        this.getSlaveConfigs()
    }

    onEditSlave = (slave) => {
        console.log(slave)
        this.setState({ editingSlave: slave })
    }

    onEditSensor = (sensor) => {
        console.log(sensor)
        this.setState({ editingSensor: sensor })
    }

    getSlaveConfigs = () => {
        this.setState({ loading: true, editingSlave: null, editingSensor: null })
        RelayApi.getSlaveConfigs(this.state.locationId)
            .then(res => {
                this.setState({ slaveConfigs: res['data'], loading: false })
            })
        // .finally(() => this.setState({ loading: false }))
    }

    sensorsRender = (sensors) => {
        const columns = [
            { title: 'Sensor type', dataIndex: 'sensor_type', key: 'sensor_type' },
            { title: 'High threshold', dataIndex: 'high_threshold', key: 'high_threshold' },
            { title: 'Low threshold', dataIndex: 'low_threshold', key: 'low_threshold' },
            {
                title: 'Action',
                dataIndex: 'operation',
                key: 'operation',
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.onEditSensor(record)}>Edit</a>
                        {/* <Divider type="vertical" />
                        <a>Delete</a> */}
                    </span>
                ),
            },
        ]

        return <Table columns={columns} dataSource={sensors} pagination={false} />
    }

    render() {
        const columns = [
            { title: 'Device ID', dataIndex: 'device_id', key: 'device_id' },
            { title: 'Device name', dataIndex: 'device_name', key: 'device_name' },
            {
                title: 'Action',
                dataIndex: 'operation',
                key: 'operation',
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.onEditSlave(record)}>Edit</a>
                        {/* <Divider type="vertical" />
                        <a>Delete</a> */}
                    </span>
                ),
            },
        ]

        return (
            <div className='.slave-config-container'>
                <Layout>
                    <Layout.Header style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                        <img src='/logo.png' height='50px' width='auto' />
                        <span> Slave configuration</span>
                    </Layout.Header>
                    <Layout.Content>
                        <Table columns={columns} dataSource={this.state.slaveConfigs} expandedRowRender={(record) => this.sensorsRender(record.sensors)} />
                    </Layout.Content>
                </Layout>
                <EditSlaveDialog slave={this.state.editingSlave} onClose={this.getSlaveConfigs} />
                <EditSensorDialog sensor={this.state.editingSensor} onClose={this.getSlaveConfigs} />
            </div>
        )
    }
}